.freeShippingFilter {
  display: none;
}

.fastDeliveryFilter {
  display: none;
}

.authorizedVendorFilter {
  display: none;
}
.freeShippingBadge {
  display: none;
}
.fastDeliveryBadge {
  display: none;
}
