// @import url("https://fonts.googleapis.com/css?family=Roboto:300,300i,400,400i,500,500i,700,700i&display=swap");
// @import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@400;700&display=swap");

h2,
.h2,
h3,
.h3,
h5,
.h5 {
  font-weight: $font-weight-normal;
}
h4,
.h4 {
  font-weight: $font-weight-lighter;
}
p,
.p {
  font-weight: $font-weight-light;
  font-size: $font-size-base;
}
.md {
  font-size: $font-size-base;
}
.lg {
  font-size: $font-size-lg;
}
.sm {
  font-size: $font-size-sm;
}
.light {
  font-weight: $font-weight-light;
}
.regular,
.normal {
  font-weight: $font-weight-normal;
}
.medium {
  font-weight: $font-weight-bold;
}

a.text-dark:hover,
a.text-dark:focus {
  color: $primary !important;
}
.text-savings {
  color: $savings-color;
}
.text-underline {
  text-decoration: underline !important;
}
body {
  -webkit-font-smoothing: antialiased;
}
