.ais-HierarchicalMenu-showMore.ais-HierarchicalMenu-showMore {
  outline: none;
  border: none;
  background: none;
  box-shadow: none;
  color: $vertical-color-50;
  &:hover {
    text-decoration: underline;
  }
}

.ais-RefinementList-showMore.ais-RefinementList-showMore {
  outline: none;
  border: none;
  background: none;
  box-shadow: none;
  color: $vertical-color-50;
  &:hover,
  &:active,
  &:focus {
    text-decoration: underline;
    outline: none;
    border: none;
    box-shadow: none;
    background-color: transparent;
    background-image: none;
  }
}

.ais-Panel-header {
  color: black !important;
  text-transform: capitalize !important;
  font-size: 14px !important;
}

.auto-complete-input-container {
  background-color: white;
  &:not(:placeholder-shown),
  &:focus,
  &:active {
    z-index: 111;
    box-shadow: none;
    outline: none;
  }
  .aa-Autocomplete {
    .aa-Form {
      border: 0;
      position: relative;
      outline: none;
      box-shadow: none;
      .aa-InputWrapper {
        flex-grow: 1;
        .aa-Input {
          height: unset;
          padding: 0.65rem 0 0.65rem 0.5rem;
          border-left: 1px solid #ddd;
        }
      }
    }
    .aa-InputWrapperPrefix {
      position: absolute;
      right: -40px;
      top: -1px;
      opacity: 0;
      height: unset;
      .aa-SubmitButton {
        padding: 0;
        height: 40px;
        width: 40px;
        opacity: 0;
      }
    }
    .aa-DetachedCancelButton {
      display: none;
    }

    .aa-InputWrapperSuffix {
      height: unset;
      .aa-ClearButton {
        padding: 0;
      }
    }
  }
}
.aa-ItemContentTitle {
  font-weight: 700;
  font-size: 14px;
}
.aa-ItemContent mark {
  padding-left: 0px;
  padding-right: 0px;
  font-weight: 400 !important;
}

.aa-InputWrapper:focus-within + .aa-InputWrapperSuffix {
  display: none;
}
.without-query .aa-InputWrapperSuffix {
  width: 100%;
  height: 0 !important;
  position: absolute;
  background-color: transparent;
  pointer-events: none;
  cursor: text;
  button {
    display: none;
  }
  &:after {
    content: "Search";
    position: absolute;
    left: 1rem;
    color: #8897a2;
  }
  &::before {
    position: absolute;
    content: "";
    animation: search-placeholder-animation 7s cubic-bezier(0, 0, 0.3642, 1) 0s infinite;
    width: calc(100% - 73px);
    left: 72px;
    top: -6px;
    font-size: 15px;
    color: #8897a2;
    font-family: inherit;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    // margin here to better align animated text and fixed Search
    margin-top: 1px;
  }
}
.aa-DetachedSearchButton {
  display: flex;
  background-color: white;
  border: none;
  outline: none;
  width: 100%;
  padding: 0.5rem 1rem;
  height: 40px !important;
  .aa-DetachedSearchButtonIcon {
    display: none;
  }
}

.aa-Panel {
  z-index: 100;
  margin-top: 0px !important;
  border-top-right-radius: 0 !important;
  border-top-left-radius: 0 !important;
  @include media-breakpoint-down(sm) {
    width: calc(100% - 61px) !important;
    left: 30px !important;
  }

  @include media-breakpoint-up(lg) {
    .aa-PanelLayout {
      display: grid;
      $firstColumnWidth: 150px;
      grid-template-columns: $firstColumnWidth 1fr;
      grid-template-rows: minmax(220px, max-content) 33px;

      .aa-Source[data-autocomplete-source-id="products"] {
        grid-column: 1 / 3;
        grid-row: 1 / 3;
        .aa-SourceHeader {
        }
        .aa-List {
          display: grid;
          grid-template-columns: 50% 50%;
          grid-template-rows: 50% 50%;
          flex-wrap: wrap;
          overflow-x: auto;
          min-height: 220px;
          .aa-Item {
            min-width: 175px;
          }
          .aa-Item:hover {
            span {
              color: #4385f6;
            }
          }
        }
        .aa-SourceFooter {
        }
      }
      .aa-Source[data-autocomplete-source-id="querySuggestionsPlugin"] {
        position: relative;
        z-index: 5;
        border-right: 1px solid #d3d3d3;
        grid-column: 1 / 2;
        grid-row: 1 / 2;
        + .aa-Source[data-autocomplete-source-id="products"] {
          grid-column: 1 / 3;
          grid-row: 1 / 3;
          display: grid;
          grid-template-columns: $firstColumnWidth 10px 1fr;
          grid-template-rows: 36px max-content 10px 33px;
          grid-template-areas:
            ".      .      header"
            ".      .      products"
            ".      .      ."
            "footer footer footer";
          .aa-SourceHeader {
            grid-area: header;
          }
          .aa-List {
            grid-area: products;
            grid-template-columns: minmax(175px, 50%) minmax(175px, 50%);
          }
          .aa-SourceFooter {
            grid-area: footer;
          }
        }
      }
    }
  }

  .aa-List {
    .aa-ItemActions,
    .aa-ItemIcon {
      display: none;
    }
  }
}

@keyframes search-placeholder-animation {
  0% {
    opacity: 1;
    content: "60,000+ manufacturer product skus";
    transform: (-2px);
  }

  4%,
  96%,
  100% {
    opacity: 1;
    content: "60,000+ manufacturer product skus";
    transform: translateY(-2px);
  }

  17% {
    opacity: 0;
    content: "60,000+ manufacturer product skus";
    transform: translateY(10px);
  }

  18% {
    opacity: 0;
    content: "all products on Net32";
    transform: translateY(-10px);
  }

  30%,
  38% {
    opacity: 1;
    content: "all products on Net32";
    transform: translateY(-2px);
  }

  51% {
    opacity: 0;
    content: "all products on Net32";
    transform: translateY(10px);
  }

  52% {
    opacity: 0;
    content: "CaviWipes";
    transform: translateY(-10px);
  }

  64%,
  72% {
    opacity: 1;
    content: "CaviWipes";
    transform: translateY(-2px);
  }

  85% {
    opacity: 0;
    content: "CaviWipes";
    transform: translateY(10px);
  }

  86% {
    opacity: 0;
    content: "60,000+ manufacturer product skus";
    transform: translateY(-10px);
  }
}
