$menu-item-height: 44px;
$max-menu-items-shown: 5;

.dropdown {
  .dropdown-toggle {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .dropdown-menu {
    padding: 0;
    margin: 0;
    min-width: 100%;
    max-height: #{$menu-item-height * $max-menu-items-shown};
    overflow-y: auto;
    overflow-x: hidden;

    &[x-placement="bottom-start"] {
      margin-top: -1px;
    }
    &[x-placement="top-start"] {
      margin-bottom: -1px;
    }
    &:not(:hover) {
      .dropdown-item.chosen {
        background-color: $vertical-color-50;
        color: color-contrast($vertical-color-50);
        text-decoration: none;
      }
    }
    .dropdown-item {
      padding: {
        right: 16px;
        left: 16px;
        top: 11px;
        bottom: 11px;
      }
      min-height: $menu-item-height;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      color: $black-color;
      &:hover {
        background-color: $vertical-color-50;
        color: color-contrast($vertical-color-50);
        text-decoration: none;
      }
    }
  }
  .btn-outline-primary + .dropdown-menu,
  .btn-primary + .dropdown-menu {
    border-color: $vertical-color-70;
    .dropdown-item {
      border-bottom: 1px solid $vertical-color-70;
      &:last-of-type {
        border-bottom: 0px;
      }
    }
  }

  .btn-outline-light + .dropdown-menu,
  .btn-light + .dropdown-menu {
    border-color: $light;
    .dropdown-item {
      border-bottom: 1px solid $light;
      &:last-of-type {
        border-bottom: 0px;
      }
    }
  }
}
