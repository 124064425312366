.ais-RefinementList-label {
  position: relative;
  height: 32px;
  .ais-RefinementList-checkbox {
    --bs-form-check-bg: var(--bs-body-bg);
    flex-shrink: 0;
    width: 1em;
    height: 1em;
    margin-top: 0.25em;
    vertical-align: top;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background-color: var(--bs-form-check-bg);
    background-image: var(--bs-form-check-bg-image);
    background-repeat: no-repeat;
    background-position: 50%;
    background-size: contain;
    border: var(--bs-border-width) solid var(--bs-border-color);
    border-radius: 0.25em;
    opacity: 0;
  }
  .ais-RefinementList-labelText,
  .star-ratings-container {
    display: inline-flex;
    &::before,
    &:after {
      content: "";
      display: block;
      position: absolute;
      top: 5px;
      left: 0;
      border-radius: 0;
      height: 16px;
      width: 16px;
      border: 1px solid;
    }
    &::after {
      border-color: #4385f6;
    }
  }

  .star-ratings-container {
    .star-rating-wrapper {
      width: 80px;
    }
    justify-content: start;
    &::before,
    &:after {
      top: 8px;
    }
    span:last-of-type {
      transform: translateX(calc(10px + 100%));
    }
  }
  span[class^="filters_ratingUpText"] {
    display: none;
  }
}

.ais-RefinementList-item.ais-RefinementList-item--selected {
  .ais-RefinementList-labelText,
  .star-ratings-container {
    &::before {
      background-color: #4385f6;
      background-image: url("/next-public/common/check.svg");
      background-size: 11px 14px;
      background-repeat: no-repeat;
      background-position: 1px;
    }
    &::after {
      border-color: #4385f6;
    }
  }
}

.ais-HierarchicalMenu-link::before {
}

.ais-HierarchicalMenu-link {
  &::before {
    display: none !important;
  }
  .ais-HierarchicalMenu-label {
    white-space: normal;
    line-height: 1.4;
    margin-bottom: 8.5px;
  }
  .ais-HierarchicalMenu-count {
    margin-bottom: 0.75rem;
  }
}
