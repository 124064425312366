.star-ratings-container {
  display: block;

  &.star-ratings-container-small {
    height: 16px;
    width: 80px;

    background-position:
      0 0,
      16px 0,
      32px 0,
      48px 0,
      64px 0;

    background-size: 16px, 16px, 16px, 16px, 16px;
  }

  &.star-ratings-container-medium {
    height: 30px;
    width: 150px;

    background-position:
      0 0,
      30px 0,
      60px 0,
      90px 0,
      120px 0;

    background-size: 30px, 30px, 30px, 30px, 30px;
  }

  background-image: url("/media/shared/common/reviews/grey_star.svg"), url("/media/shared/common/reviews/grey_star.svg"),
    url("/media/shared/common/reviews/grey_star.svg"), url("/media/shared/common/reviews/grey_star.svg"),
    url("/media/shared/common/reviews/grey_star.svg");

  background-repeat: no-repeat, no-repeat, no-repeat, no-repeat, no-repeat;

  color: rgba(0, 0, 0, 0);
  overflow: hidden;
  text-indent: 100%;
  &.starRatingDiv {
    width: 60%;
  }

  span.activeCount {
    text-indent: 0%;
    line-height: normal;
    color: #23263b;
    transform: none !important;
  }
  .star-ratings {
    display: block;

    &.star-ratings-small {
      height: 16px;
      width: 80px;

      background-position:
        0 0,
        16px 0,
        32px 0,
        48px 0,
        64px 0;

      background-size: 16px, 16px, 16px, 16px, 16px;
    }

    &.star-ratings-medium {
      height: 30px;
      width: 150px;

      background-position:
        0 0,
        30px 0,
        60px 0,
        90px 0,
        120px 0;

      background-size: 30px, 30px, 30px, 30px, 30px;
    }

    background-repeat: no-repeat, no-repeat, no-repeat, no-repeat, no-repeat;
    color: rgba(0, 0, 0, 0);
    overflow: hidden;
    text-indent: 100%;

    &.star-ratings-primary {
      background-image: url($vertical-star-image-path), url($vertical-star-image-path), url($vertical-star-image-path),
        url($vertical-star-image-path), url($vertical-star-image-path);
    }
    &.star-ratings-review {
      background-image: url(/media/shared/common/reviews/gold_star.svg), url(/media/shared/common/reviews/gold_star.svg),
        url(/media/shared/common/reviews/gold_star.svg), url(/media/shared/common/reviews/gold_star.svg),
        url(/media/shared/common/reviews/gold_star.svg);
    }
  }

  &.rating-filter {
    width: 16px;
    display: inline-block;
    transform: translateY(3.5px);
    margin-right: 4px;
    .star-ratings.star-ratings-review {
      width: 100%;
    }
  }
}

.vendor-entry-rating-gray-stars {
  .vendor-entry-rating-stars {
  }
}
