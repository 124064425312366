@mixin noTransitionBuilder($themeColor) {
  &.no-transition:hover,
  &.no-transition:active,
  &.no-transition:focus,
  &.no-transition {
    color: $themeColor;
    background: #ffffff !important;
  }
}
.btn {
  overflow: hidden;
  &:disabled {
    cursor: not-allowed;
  }
  // The following is for webflow
  letter-spacing: normal !important;
  text-transform: none;
}
.btn.btn-thin {
  padding-top: 6px;
  padding-bottom: 6px;
}
.btn-primary:disabled {
  background-color: $vertical-color-70;
  border-color: $vertical-color-70;
  opacity: 1;
}
.btn-secondary:disabled {
  color: white !important;
}
.btn-outline-primary:disabled {
  color: $vertical-color-70;
  border-color: $vertical-color-70;
  opacity: 1;
}
.btn-secondary,
.btn-secondary:hover,
.btn-outline-secondary:hover:not(:disabled) {
  color: $color-contrast-light;
}
.btn-secondary:active,
.btn-outline-secondary:active:not(:disabled) {
  color: $color-contrast-light !important;
}
.btn-pillbox,
.btn-rounded,
.btn-circular {
  padding-left: 20px;
  padding-right: 20px;
  border-radius: 50px;
}
.btn-outline-light {
  color: $darker-black;
}
.btn-outline-light:hover,
.btn-outline-light:not(:disabled):not(.disabled):active,
.btn-outline-light:not(:disabled):not(.disabled).active,
.show > .btn-outline-light.dropdown-toggle {
  background: #ffffff;
}
.btn-outline-primary,
.btn-outline-secondary,
.btn-outline-success,
.btn-outline-info,
.btn-outline-warning,
.btn-outline-danger,
.btn-outline-light,
.btn-outline-dark {
  background: #ffffff;
  box-shadow: none !important;

  &.no-transition:hover,
  &.no-transition:active,
  &.no-transition:focus,
  &.no-transition {
    background: #ffffff !important;
  }
}
.btn-primary,
.btn-secondary,
.btn-success,
.btn-info,
.btn-warning,
.btn-danger,
.btn-light,
.btn-dark,
.btn-link {
  box-shadow: none !important;
}

.btn-outline-primary,
.show > .btn-outline-primary {
  @include noTransitionBuilder("primary");
}
.btn-outline-secondary,
.show > .btn-outline-secondary {
  @include noTransitionBuilder("secondary");
}
.btn-outline-success,
.show > .btn-outline-success {
  @include noTransitionBuilder("success");
}
.btn-outline-info,
.show > .btn-outline-info {
  @include noTransitionBuilder("info");
}
.btn-outline-warning,
.show > .btn-outline-warning {
  @include noTransitionBuilder("warning");
}
.btn-outline-danger,
.show > .btn-outline-danger {
  @include noTransitionBuilder("danger");
}
.btn-outline-light,
.show > .btn-outline-light {
  @include noTransitionBuilder("light");
}
.btn-outline-dark,
.show > .btn-outline-dark {
  @include noTransitionBuilder("secondary");
}
.black-text-link {
  color: $black-color;
}

.btn.loading-simple {
  &::after {
    animation: ellipsis-md steps(6, end) 750ms infinite;
    position: absolute;
    display: inline-block;
    overflow: hidden;
    content: "\2026";
    width: 0;
    vertical-align: bottom;
  }
  &.btn-lg::after {
    animation: ellipsis-lg steps(6, end) 750ms infinite;
  }
  &.btn-sm::after {
    animation: ellipsis-sm steps(6, end) 750ms infinite;
  }
}

.btn.loading-complex {
  position: relative;
  z-index: 1;
  &:before,
  &:after {
    position: absolute;
    color: #fff !important;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 2;
  }
  &:before {
    content: "\25CF\2003\25CF";
    z-index: 1;
    animation: alternate-fade-dots-outer 1s infinite;
  }
  &:after {
    content: "\25CF";
    animation: alternate-fade-dots-inner 1s infinite;
  }

  &.btn-outline-primary,
  &.btn-primary {
    background-color: $primary !important;
    color: $primary !important;
  }
  &.btn-outline-secondary,
  &.btn-secondary {
    background-color: $secondary !important;
    color: $secondary !important;
  }
  &.btn-outline-success,
  &.btn-success {
    background-color: $success !important;
    color: $success !important;
  }
  &.btn-outline-info,
  &.btn-info {
    background-color: $info !important;
    color: $info !important;
  }
  &.btn-outline-warning,
  &.btn-warning {
    background-color: $warning !important;
    color: $warning !important;
  }
  &.btn-outline-danger,
  &.btn-danger {
    background-color: $danger !important;
    color: $danger !important;
  }
  &.btn-outline-light,
  &.btn-light {
    background-color: $light !important;
    color: $light !important;
  }
  &.btn-outline-dark,
  &.btn-dark {
    background-color: $dark !important;
    color: $dark !important;
  }
}

button.btn {
  &.btn-link {
    text-decoration: none;
    background-color: inherit;
    &:hover {
      text-decoration: underline;
    }
  }
}

@keyframes ellipsis-lg {
  to {
    width: 18px;
  }
}
@keyframes ellipsis-md {
  to {
    width: 14px;
  }
}
@keyframes ellipsis-sm {
  to {
    width: 12px;
  }
}

@keyframes alternate-fade-dots-outer {
  0% {
    opacity: 0.3;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0.3;
  }
}
@keyframes alternate-fade-dots-inner {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.3;
  }
  100% {
    opacity: 1;
  }
}
