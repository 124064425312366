@import "./themeVars";
// Required
@import "bootstrap/scss/bootstrap";
$link-decoration: none;

//  Additional bootstrap override/additions imports
@import "./animations";
@import "./badge";
@import "./buttons";
@import "./cards";
@import "./carousel";
@import "./dropdowns";
@import "./fonts";
@import "./forms";
@import "./modal";
@import "./popovers";
@import "./productRecommendations";
@import "./starRatings";
@import "algoliaAutoComplete";
@import "./algoliaRefinements";
@import "./abTastyStyles";

@font-face {
  font-family: "3M Circular TT Book";
  src:
    url("/web-fonts/3MCircularTT-Book.woff2") format("woff2"),
    url("/web-fonts/3MCircularTT-Book.woff") format("woff");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "3M Circular TT";
  src:
    url("/web-fonts/3MCircularTT-Light.woff2") format("woff2"),
    url("/web-fonts/3MCircularTT-Light.woff") format("woff");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "3M Circular TT";
  src:
    url("/web-fonts/3MCircularTT-Bold.woff2") format("woff2"),
    url("/web-fonts/3MCircularTT-Bold.woff") format("woff");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

#full-page {
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 100vh;
}
html {
  scroll-behavior: smooth;
}
body:not(.accepting-default-styling) {
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 100vh;

  @each $color, $value in $theme-colors {
    .bg-#{$color}.bg-lighten {
      background-color: lighten($value, 35%) !important;
    }
  }
  .hide-until-css-loaded {
    visibility: hidden;
  }
}
// body * {
//   font-family: var(--n32-roboto-font);
// }
.grecaptcha-badge {
  display: none !important;
}
// .text-muted was deprecated with bootstrap v5.3 and is marked for removal in v6.  For backwards compatbility to v4 version has been put here.
.text-muted {
  color: #a1a1a1 !important;
}
#page-wrapper {
  &.is-loading {
    cursor: progress;
    * {
      cursor: progress !important;
    }
  }
}
.loading-placeholder {
  animation-duration: 1.8s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: loadingShimmer;
  animation-timing-function: linear;
  background: #f6f7f8;
  background: linear-gradient(to right, #fafafa 8%, #f4f4f4 38%, #fafafa 54%);
  background-size: 1000px 640px;

  position: relative;
}
@keyframes loadingShimmer {
  0% {
    background-position: -468px 0;
  }

  100% {
    background-position: 468px 0;
  }
}
// @import "swiper/swiper.min.css";
