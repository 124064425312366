.badge {
  // Left here for backwards compatibility.  Use `outline-{color}` in "variant" prop instead
  &.inverted {
    background-color: white !important;
    @each $color, $value in $theme-colors {
      @if $color == "light" {
        &.badge-light {
          color: #999 !important;
          border: 1px solid #ddd !important;
        }
      } @else {
        &.badge-#{$color} {
          color: $color !important;
          border: 1px solid $color !important;
        }
      }
    }
  }
  @each $color, $value in $theme-colors {
    @if $color == "light" {
      &.badge-outline-light {
        color: #999 !important;
        border: 1px solid #ddd !important;
      }
    } @else {
      &.badge-outline-#{$color} {
        color: $color !important;
        border: 1px solid $color !important;
      }
    }
  }
  &.badge-savings {
    &:not(.inverted) {
      color: white;
      background-color: $savings-color;
    }
    &.inverted {
      color: $savings-color;
      border: 1px solid $savings-color;
    }
  }
  &.badge-sm {
    font-size: 10px;
    font-weight: 400;
    padding: {
      left: 2px;
      right: 2px;
      top: 2px;
      bottom: 1px;
    }
  }
  &.badge-pillbox {
    padding: 2px 8px;
    border-radius: 100px;
  }
}
