.form-check-input {
  min-width: $form-check-input-width;
}
input.form-control,
select.form-control,
div.form-control // For use with stripe and other applications with a pho-input
{
  outline: none !important;
  box-shadow: none !important;
  &:hover {
    border-color: $vertical-color-50;
  }
  &:disabled,
  &.disabled {
    border-color: $light-grey;
    background-color: $lightest-grey;
    color: $medium-grey;
  }
  // The following is for when you have a div.form-control  parent and a "real" input below it.  This is required
  // when you have, for example, a dropdownt hat needs to trigger off of an input.  See the quick-order item on the shopping cart
  &.has-hidden-interior-input {
    padding: 0 !important;
    .form-control {
      border: 0;
      height: #{$input-height - 0.125rem}; // 0.125rem = 2px;
    }
  }

  &.is-valid,
  &.is-invalid,
  &.is-invalid:focus {
    border: $input-border-width solid $input-border-color;
    &:hover {
      border: $input-border-width solid $vertical-color-50;
    }
  }
}
input.form-control,
div.form-control {
  &:valid,
  &:invalid,
  &.is-valid,
  &.is-invalid {
    background-image: none !important;
  }
}

select.form-control {
  -webkit-appearance: none;
  -moz-appearance: none;
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAkAAAAMCAQAAAAaVV8oAAAAPklEQVR4Xo3OQQ2AMBAEwLGEBzTcBwP4qAi8coSWJ7mEhtn3btZMKA635mWXutMGsLp0T6TFb7VY56cnIHwbQgwXHSb7R/wAAAAASUVORK5CYII=) !important;
  background-position: right 6px center !important;
  background-repeat: no-repeat !important;
  background-size: auto !important;
  padding-right: 18px;

  &:valid,
  &:invalid,
  &.is-valid,
  &.is-invalid {
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAkAAAAMCAQAAAAaVV8oAAAAPklEQVR4Xo3OQQ2AMBAEwLGEBzTcBwP4qAi8coSWJ7mEhtn3btZMKA635mWXutMGsLp0T6TFb7VY56cnIHwbQgwXHSb7R/wAAAAASUVORK5CYII=) !important;
  }
}
.fancy-select {
  -webkit-appearance: none;
  -moz-appearance: none;
  position: relative;
  padding-right: 22px !important;
  height: auto !important;
  overflow: visible;

  &:after {
    content: "\25BE";
    display: block;
    font-size: 14px;
    line-height: 14px;
    position: absolute;
    top: calc(50% - 7px);
    right: 10px;
  }
  small {
    color: $medium-grey;
  }
  .list-group {
    z-index: 10;
    width: 100%;
    overflow-y: auto;
    overflow-x: hidden;
    $list-item-typical-height: 46px;
    max-height: #{$list-item-typical-height * 5 + 1}; // the +1 is for making sure the scroll bar does not appear when at or under the height limit
    border-bottom: $list-group-border-width solid $list-group-border-color;
    @each $key, $value in $grid-breakpoints {
      @include media-breakpoint-up($key) {
        @for $i from 1 through 10 {
          &.#{$key}-menu-max-height-#{$i} {
            max-height: #{$list-item-typical-height *
              $i +
              1}; // the +1 is for making sure the scroll bar does not appear when at or under the height limit
          }
        }
      }
    }
    .list-group-item:last-of-type {
      border-bottom: none;
    }
  }
}
.form-control:focus,
.form-control.StripeElement--focus {
  border-color: $vertical-color-50;
}
.form-control.is-invalid:focus,
.was-validated .form-control:invalid:focus {
  border-color: $danger;
}

.custom-checkbox,
.custom-radio {
  .form-check-label::before,
  .custom-file-label,
  .custom-select {
    transition: none;
  }
}
.custom-checkbox {
  position: relative;
  padding: {
    left: 1.75em;
  }
  .form-check-input {
    opacity: 0;
  }
  .form-check-label {
    min-height: 20px;
    &::before,
    &::after {
      content: "";
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      border-radius: 0;
      height: 20px;
      width: 20px;
    }
    &::before {
      border: #adb5bd solid 1px;
    }
  }

  .form-check-input:checked ~ .form-check-label::before,
  .form-check-input:active ~ .form-check-label::before {
    border-color: $primary;
    background-color: $primary;
  }
  .form-check-input:checked ~ .form-check-label::after,
  .form-check-input:active ~ .form-check-label::after {
    background: no-repeat 100% / 100% 50%;
    background-image: url("/check.svg");
    // background-image: url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyB3aWR0aD0iMTRweCIgaGVpZ2h0PSIxMXB4IiB2aWV3Qm94PSIwIDAgMTQgMTEiIHZlcnNpb249IjEuMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayI+CiAgICA8IS0tIEdlbmVyYXRvcjogU2tldGNoIDU5LjEgKDg2MTQ0KSAtIGh0dHBzOi8vc2tldGNoLmNvbSAtLT4KICAgIDx0aXRsZT5pbnNpZGUtY2hlY2s8L3RpdGxlPgogICAgPGRlc2M+Q3JlYXRlZCB3aXRoIFNrZXRjaC48L2Rlc2M+CiAgICA8ZyBpZD0iS2xhdml5byIgc3Ryb2tlPSJub25lIiBzdHJva2Utd2lkdGg9IjEiIGZpbGw9Im5vbmUiIGZpbGwtcnVsZT0iZXZlbm9kZCI+CiAgICAgICAgPHBvbHlnb24gaWQ9Imluc2lkZS1jaGVjayIgZmlsbD0iI0ZGRkZGRiIgcG9pbnRzPSI1IDExIDAgNiAxLjQxIDQuNTkgNSA4LjE3IDEyLjU5IDAuNTggMTQgMiI+PC9wb2x5Z29uPgogICAgPC9nPgo8L3N2Zz4=");
  }
  &.inverted .form-check-input:checked ~ .form-check-label::before,
  &.inverted .form-check-input:not(:disabled):active ~ .form-check-label::before {
    background-color: #ffffff !important;
  }
  &.inverted .form-check-input:checked ~ .form-check-label::after,
  &.inverted .form-check-input:active ~ .form-check-label::after {
    background: no-repeat 100% / 100% 50%;
    border: 1px solid $primary;
    background-color: $primary;
    background-image: $vertical-checkbox;
  }
  .form-check-input:active ~ .form-check-label::after {
    opacity: 0.5;
  }
  .form-check-input:active ~ .form-check-label::before {
    box-shadow: inset 1px 1px 0 1px rgba(0, 0, 0, 0.2);
  }
}
input::placeholder {
  color: #a6a9ab !important;
}
.custom-radio {
  .form-check-label {
    &::before,
    &::after {
      width: 20px;
      height: 20px;
      border: 0px;
      background-color: transparent;
      box-shadow: inset 0 0 0 1px $vertical-color-70;
      top: 0;
    }
    &::after {
      background-image: none !important;
      box-shadow: none;
    }
  }

  .form-check-input:checked ~ .form-check-label::before {
    background-color: transparent;
    border-color: transparent;
    box-shadow: inset 0 0 0 6px $vertical-color-50;
  }
}
