.carousel {
  .carousel-indicators {
    z-index: 2;

    li {
      background-color: #c6cfde;
      // box-sizing: content-box;
      // flex: 0 1 auto;
      width: 10px;
      height: 10px;
      margin-right: 7px;
      margin-left: 7px;
      // text-indent: -999px;
      border-radius: 50%;
      // cursor: pointer;
      // background-color: #fff;
      // background-clip: padding-box;
      // border-top: 10px solid transparent;
      // border-bottom: 10px solid transparent;
      opacity: 1;
      transition: background-color 0.6s ease;
      &.active {
        background-color: $primary;
      }
    }
  }
}
