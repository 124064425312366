.modal-dialog {
  &:not(.react-draggable) {
    transform: none !important; // disables the downward shift
  }
  .modal-content {
    border-radius: 4px;
    border: 0px;
    .modal-header {
      &:after {
        content: "";
        display: block;
        flex-grow: 1;
        flex-shrink: 1;
        flex-basis: auto;
        order: 98;
      }
      .btn-close {
        margin: 0;
        width: 20px;
        height: 20px;
        flex-grow: 0;
        flex-shrink: 1;
        flex-basis: 20px;
        padding: 0 !important;
        background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAAAXNSR0IArs4c6QAAAghJREFUOBGtVU1LAlEUdcyPtQoK06ZtoCtdtKpfECoI/oD+QplQgaRBH7v27dq4aqxfkCsDBU0jahVECiK2cJMOZOcM84bpOYRWD573vnvPOe++j3kqLocWj8e9k8kkhRR7YjqdqoQpitKFqaNrPp9PazQaOuP2ptgH9KPRaBrmDOQX2DJsNRgMvsF3DYfDZYivw83CrsDudDqdK9jZVigU3BA7RX+OxWIbs4jvEWKIJYdckbUqZALVrCGRbLfb7wLwk4VoAPkKqq2h0hyxhjLEuEzu19xiJJsTJ8k1NVwKD2A8Hj+iui0AbglctHH5qPLC7/evuiGW5gHIYpixCWBRFke8hPi9PU4uNXgz3HBYctkOoI/4DWbdB/lY5Ex/D+OKiNksNVIe/CRAPrQlDBezHkDAC9FdWCNGH9gT5mQ84lXktz34UUOhkHHPZBCIeYpRiDlTLC/jOOZdHQwGqnV/nECLxHRdN64g97DLL8CJzD0Ty2R19Blzwo5GI5Va3MM6gPycnuxAEItCjEtnTiwfVpf30dSoc8kaepYEqfH0j4QYc/RRRQnkTQnLITW0f7/YS71e7zMcDr9C/TwSiVz2+/0PTjdvw/IDqPga+Fyr1XowTtl8grj0CgGLiJGDrolnzLo2mUwmj5lq6HcQnev5IpYcckUR1vMlAuar8esHdkaQwn/5C/gCLcAm76BdeNsAAAAASUVORK5CYII=");
        background-position: center center;
        background-size: 20px 20px;
        background-repeat: no-repeat;
        opacity: 1;
        order: 99;

        span {
          display: none;
        }
      }
    }
    .modal-body {
      padding: {
        top: 0;
        left: 1.5rem;
        right: 1.5rem;
        bottom: 1.5rem;
      }
    }
  }
}
.modal-backdrop.show {
  opacity: 0.3;
}
