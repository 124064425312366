$padding-for-arrow: 60px; // Keep this in sync with the same variable in homepage.scss
.product-recommendations-title {
  padding: {
    left: $padding-for-arrow;
    right: $padding-for-arrow;
    bottom: 16px;
    top: 16px;
  }

  @include media-breakpoint-between(xs, md) {
    padding: {
      left: 16px;
      right: 16px;
      top: 16px;
      bottom: 8px;
    }
    margin: 0;
    font-size: 16px !important;
    font-weight: 500 !important;
  }
  &.loading-title {
    visibility: hidden;
  }
}
.recently-viewed-title {
  @include media-breakpoint-up(md) {
    display: flex;
    justify-content: center;
  }
}
$mobile-recommendations-image-width: 90px;

.center-display {
  display: flex;
  justify-content: center;
}

.product-recommendation-container {
  outline: none !important;
  // margin-bottom: -1px; // This is to erase the bottom border on containers without multiple rows
}
.product-recommendation {
  display: grid;
  outline: none;
  padding: 10px;

  &:hover {
    text-decoration: none !important;
  }
  > a {
    display: block !important;
  }
  .recommendation-image {
    grid-area: recommendation-image;
    display: flex !important;
    justify-content: center;
    align-items: center;
    outline: none !important;
    box-shadow: none !important;
    img,
    .no-image {
      width: 140px;
      height: 140px;
      margin: 0;
      padding: 0;
      outline: none !important;
      box-shadow: none !important;
    }
  }

  .recommendation-brand {
    grid-area: recommendation-brand;

    font-weight: 500;
    line-height: 18px;
    color: #000000;

    &:hover {
      text-decoration: underline !important;
      color: $primary !important;
    }
  }

  .recommendation-title {
    grid-area: recommendation-title;
    &:hover {
      text-decoration: underline !important;
      color: $primary !important;
    }
  }
  .recommendation-price,
  .recommendation-rating,
  .recommendation-savings {
    &:hover {
      text-decoration: none !important;
    }
  }
  .recommendation-price {
    grid-area: recommendation-price;
    color: $secondary;
    font-size: 15px;
    font-weight: 500;
  }

  .recommendation-savings {
    grid-area: recommendation-savings;
    .strike {
      text-decoration: line-through;
      color: #565656;
      font-weight: 300;
    }
    .savings {
      font-weight: 500;
      color: $savings-color;
    }
  }
  .recommendation-add-to-cart-container {
    grid-area: recommendations-add-to-cart;
    display: flex;
    align-items: flex-end;
    height: 99%;
    .btn {
      padding-top: 7px;
      padding-bottom: 7px;
    }
    .recommendation-add-to-cart-button {
      width: 100%;
    }
  }

  @include media-breakpoint-up(md) {
    grid-template-columns: 1fr 5fr;
    grid-template-rows:
      140px
      auto
      auto
      20px
      30px
      1fr;
    grid-template-areas:
      "recommendation-image        recommendation-image"
      "recommendation-brand        recommendation-brand"
      "recommendation-title        recommendation-title"
      "recommendation-price        recommendation-rating"
      "recommendation-savings      recommendation-savings"
      "recommendations-add-to-cart recommendations-add-to-cart";
    border-right: 1px solid #eeeeee;
    border-bottom: 1px solid #eeeeee;
    height: 283px;
    .recommendation-image {
      justify-content: center;
      align-items: center;
      img,
      .no-image {
        max-width: 140px;
        max-height: 140px;
      }
    }
    .recommendation-title {
      line-height: 18px;
      max-height: 54px;
      min-height: 18px;
      overflow: hidden;
    }

    .recommendation-price {
      padding-right: 0.5rem;
    }
  }

  @include media-breakpoint-between(xs, md) {
    min-height: 107px;
    grid-template-columns: $mobile-recommendations-image-width auto 1fr;
    grid-template-rows:
      auto
      auto
      24px
      30px
      auto;
    grid-template-areas:
      "recommendation-image recommendation-brand        recommendation-brand"
      "recommendation-image recommendation-title        recommendation-title"
      "recommendation-image recommendation-rating       recommendation-rating"
      "recommendation-image recommendation-savings      recommendation-price"
      ".                    recommendations-add-to-cart recommendations-add-to-cart";
    padding: 10px;
    border-bottom: 1px solid #eeeeee;
    .recommendation-title {
      font-size: 13px;
      line-height: 16px;
      max-height: 32px;
      min-height: 16px;
      overflow: hidden;
    }

    .recommendation-brand {
      font-size: 13px;
      line-height: 16px;
      overflow: hidden;
    }

    .recommendation-savings {
      padding-right: 0.5rem;
      font-size: 11px;
      font-weight: 300;
      .strike {
        display: block;
        padding-top: 5px;
      }
    }
    .recommendation-price {
      font-size: 17px;
      color: $black-color;
    }
    .recommendation-image {
      justify-content: flex-start;
      align-items: flex-start;
      img,
      .no-image {
        max-width: 70px;
        max-height: 70px;
      }
    }
    .recommendation-rating {
      .recommendation-ratings-count {
        font-size: 12px !important;
        color: $primary !important;
        padding-top: 4px;
        &::before {
          display: inline;
          content: "(";
        }
        &::after {
          display: inline;
          content: ")";
        }
      }
    }
    .recommendation-savings .savings {
      display: none;
    }
  }

  .recommendation-add-to-cart-button {
    display: none;
  }
  &.loading-recommendation {
    > * {
      color: transparent !important;
      visibility: hidden;
      pointer-events: none;
      // &:not(.recommendation-image),
      // .no-image {
      //   background-color: #;
      // }
      // border: 2px solid #fff;
    }
  }
}
.product-recommendation,
.sidebar-recommendation {
  .recommendation-rating {
    $width: 75px;
    grid-area: recommendation-rating;
    // height: 15px;
    width: $width;
    background-image: url(/media/shared/common/reviews/grey_star.svg), url(/media/shared/common/reviews/grey_star.svg),
      url(/media/shared/common/reviews/grey_star.svg), url(/media/shared/common/reviews/grey_star.svg),
      url(/media/shared/common/reviews/grey_star.svg);
    background-repeat: no-repeat, no-repeat, no-repeat, no-repeat, no-repeat;
    background-position: 0, 15px, 30px, 45px, 60px;
    background-size: 15px;
    position: relative;
    padding-right: 25px;
    &.recommendating-rating-0_0 {
      visibility: hidden;
    }
    .recommendation-ratings-count {
      position: absolute;
      left: $width;
      padding-left: 5px;
      font-size: 15px;
      white-space: nowrap;
    }
    .recommendation-ratings-inner-track {
      background-image: url(/media/shared/common/reviews/gold_star.svg), url(/media/shared/common/reviews/gold_star.svg),
        url(/media/shared/common/reviews/gold_star.svg), url(/media/shared/common/reviews/gold_star.svg),
        url(/media/shared/common/reviews/gold_star.svg);
      background-repeat: no-repeat, no-repeat, no-repeat, no-repeat, no-repeat;
      background-position: 0, 15px, 30px, 45px, 60px;
      background-size: 15px;
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      &.recommendating-rating-0_0 {
        width: 0%;
      }
      &.recommendating-rating-0_5 {
        width: 10%;
      }
      &.recommendating-rating-1_0 {
        width: 20%;
      }
      &.recommendating-rating-1_5 {
        width: 30%;
      }
      &.recommendating-rating-2_0 {
        width: 40%;
      }
      &.recommendating-rating-2_5 {
        width: 50%;
      }
      &.recommendating-rating-3_0 {
        width: 60%;
      }
      &.recommendating-rating-3_5 {
        width: 70%;
      }
      &.recommendating-rating-4_0 {
        width: 80%;
      }
      &.recommendating-rating-4_5 {
        width: 90%;
      }
      &.recommendating-rating-5_0 {
        width: 100%;
      }
    }
  }
}
.product-recommendation {
  .recommendation-rating {
    .recommendation-ratings-count {
      color: $early_grey;
    }
  }
}

.sidebar-recommendations-container {
  .sidebar-recommendations-title {
    font-size: 16px;
    font-weight: 500;
    &.loading-title {
      visibility: hidden;
    }
  }
  .sidebar-recommendations-list {
    .sidebar-recommendation {
      display: grid;
      grid-template-columns: 94px 6px auto;
      grid-template-rows:
        auto
        auto
        auto
        1fr;
      grid-template-areas:
        "prod-image . brand"
        "prod-image . title"
        "prod-image . recommendation-rating"
        "prod-image . prices";
      .sidebar-recommendation-image {
        grid-area: prod-image;
        display: flex;
        align-items: flex-start;
        justify-content: center;
        img,
        .no-image {
          max-width: 94px;
          max-height: 94px;
          width: 94px;
          height: 94px;
        }
      }
      .recommendation-rating {
        min-height: 18px;
        .recommendation-ratings-count {
          font-size: 12px;
          color: $primary !important;
        }
      }
      .sidebar-recommendation-title {
        grid-area: title;
        font-size: 14px;
        line-height: 21px;
        max-height: 63px;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      .sidebar-recommendation-brand {
        grid-area: brand;
        font-size: 14px;
        line-height: 21px;
        max-height: 63px;
        overflow: hidden;
        text-overflow: ellipsis;

        font-weight: 500;
        line-height: 18px;
        color: #000000;
      }

      .sidebar-recommendation-pricing {
        grid-area: prices;
        text-decoration: none;
        min-height: 30px;
      }
      &.loading-recommendation {
        > * {
          color: transparent !important;
          visibility: hidden;
          pointer-events: none;
          // &:not(.recommendation-image),
          // .no-image {
          //   background-color: #;
          // }
          // border: 2px solid #fff;
        }
      }
    }
  }
}
